/* MainContainer Component styles */
.main-container {
  display: flex;
  justify-content: space-between;
}

/* Optional: Add media query to adjust the layout on smaller screens */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }
}

.content-container {
  flex-grow: 1; /* Allow the container to grow and fill remaining space */
  padding: 20px; /* Add some padding around the content */
  /* Add any other styles for the content container as needed */
}
