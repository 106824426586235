.how-it-works {
  text-align: center;
  padding: 50px 20px 50px 20px;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 12px;
}

.steps-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.step {
  flex: 1;
  max-width: 300px;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
}

.icon {
  font-size: 80px; /* Adjust size as needed */
  height: 56px;
  width: 56px;
}

.icon-background {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #343a40; /* dark grey */
  margin: 0 auto 20px auto;
}

.step-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #00c6ff; /* blue color */
}

.step-description {
  font-size: 1rem;
  color: #ffffff; /* grey color */
}
