/* ProductSearch Component styles */
.search-bar {
  /* ... (existing styles for the search bar) */
  -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 3.6rem;
    padding: 1rem;
    border: none;
    -webkit-transition: background .2s;
    transition: background .2s;
    background: #fff;
    margin: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    width: 100% !important;
}

.product-search-container {
  width: 100%;
}

.product-search-results {
  background-color: white;
  width: 50%;
  color: black;
  font-family: Roboto;
  font-size: 16px;
}

.product-search-results img {
  margin-right: 20px;
}

.product-search-results span {
  /* position: relative;
  top: 50%;
  transform: translateY(-50%); */
}

.product-search-result-item {
  padding: 15px;
  display: table;
  cursor: pointer;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.product-search-result-item:hover {
  background-color: rgba(0,198,255,1);
  color: white;
}

.product-search-result-item span {
  display:table-cell;
  vertical-align:middle;
  height: 40px;
  width: 100%;
}

.site-search_controls {
    display: flex;
}

.site-search__controls__clear {
    margin: auto 0.5rem;
}

.rc-icon-button {
    display: inline-block;
}

/* Product grid styles */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.product-item {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
  color: #3AC5FF;
}

.product-item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.product-item h3 {
  font-size: 1.2rem;
  margin: 0;
}

.product-item p {
  margin: 10px 0 0;
}

.remove-from-cart-btn {
  border: 2px solid #3bc6ff;
  background-color: white;
  color: #3bc6ff;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-from-cart-btn:hover {
  background-color: #3AC5FF;
  color: white;
}

.add-to-list-btn {
  background-color: #3AC5FF;
  color: white;
  border: none;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-to-list-btn:hover {
  background-color: #00A7ED;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 5px 10px;
  margin: 0 2px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.pagination button:not(.active):hover {
  background-color: #f1f1f1;
}

.pagination button:disabled,
.pagination button.active {
  background-color: #f1f1f1;
  color: #999;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
  cursor: not-allowed;
}
