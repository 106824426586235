@import url('https://fonts.googleapis.com/css?family=Lato:400,500,600,700&display=swap');
/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
} */
/* html,body{
  display: grid;
  height: 100%;
  place-items: center;
  background: #0069d9;
  font-family: 'Lato', sans-serif;
} */

.cart-item-info {
  width: 100%;
}

.cart-item {
  border-bottom: none;
}

.cart-item-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-method-container{
  margin-left: 24px;
}
.payment-method-container .option{
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.payment-method-container .option .dot{
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.payment-method-container .option .dot::before{
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #3AC5FF;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
input[type="radio"]{
  display: none;
}
#store-credit:checked:checked ~ .store-credit,
#cash:checked:checked ~ .cash{
  border-color: #3AC5FF;
  background: #3AC5FF;
}
#store-credit:checked:checked ~ .store-credit .dot,
#cash:checked:checked ~ .cash .dot{
  background: #fff;
}
#store-credit:checked:checked ~ .store-credit .dot::before,
#cash:checked:checked ~ .cash .dot::before{
  opacity: 1;
  transform: scale(1);
}
.payment-method-container .option span{
  font-size: 20px;
  color: #808080;
}
#store-credit:checked:checked ~ .store-credit span,
#cash:checked:checked ~ .cash span{
  color: #fff;
}
