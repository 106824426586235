.navigation-bar {
  position: relative;
  overflow: hidden;
}

.navigation-bar-content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.elementor-background-video-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
}

.elementor-widget-container {
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
}

.elementor-widget-container:hover {
  transform: translate(-50%, -50%) scale(0.9);
}

.navigation-bar-links {
  position: absolute;
  top: 25%;
  right: 50px;
}

.navigation-bar-links li {
  list-style-type: none;
}

.navigation-bar-links a {
  color: white;
}
