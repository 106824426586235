.clear {
  clear: both;
  height: 60px;
}

.cart-page {
  padding: 48px 0px 48px 0px;
  margin: auto;
  min-width: 800px;
}

.checkout-page {
  padding: 48px 160px;
  margin: auto;
  min-width: 800px;
}


.divider {
  border-color: rgb(142 142 142 / 30%) !important;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align items horizontally */
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.centered {
  margin: auto;
}

.cart-item-info {
  display: inline-flex;
}

.cart-item img {
  width: 60px;
  max-height: 60px; /* Set a fixed height for the images */
}

.cart-item-details {
  display: flex;
  align-items: center;
}

.cart-item-metadata {
  padding-top: 16px;
  margin-left: 24px;
  margin-right: 48px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.cart-item-metadata p {
  margin-top: 0px;
  margin-bottom: 8px;
}

.price-quantity {
  display: flex;
}

.price-quantity p {
  margin-right: 16px;
}

.quantity-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.quantity-container label {
  margin-right: 8px;
}

.remove-from-cart-btn {
  background-color: white;
  color: #3AC5FF;
  border: 2px solid #3AC5FF;
  /* padding: 8px 12px; */
  cursor: pointer;
}

.remove-from-cart-btn:hover {
  background-color: #3AC5FF33;
  color: #3AC5FF;
}
