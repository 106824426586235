/* CartSummaryPanel.css */
.cart-summary {
  background-image: linear-gradient(205deg, #000000 0%, #555555 100%);
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh; /* Full height of the viewport */
  width: 350px; /* Adjust as needed */
  background-color: #f9f9f9;
  padding: 20px;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1); /* Shadow on the left side */
}

/* Style for each summary section */
.summary-section {
  margin-bottom: 50px;
}

/* Style for the overall total section */
.overall-total {
  margin-top: auto; /* Push it to the bottom */
}

.overall-total p {
  text-align: right;
}

.align-right {
  text-align: right;
}
